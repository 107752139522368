import axios from "axios";

export const createStripePaymentIntent = async ({ courseId }) => {
  try {
    console.log("Course ID in createStripePaymentIntent", courseId);
    const apiUrl = `${process.env.REACT_APP_API_CREATE_STRIPE_PAYMENT_INTENT}${courseId}/payment-intents/`;
    const response = await axios.post(
      apiUrl,
      {},
      {},
    );
    if (response.status === 201) {
      return response.data;
    }
  } catch (error) {
    console.error("Error creating stripe payment intent", error);
    throw error;
  }
};

export const sendPaymentCompleteConfirmation = async ({ sectionId }) => {
  try {
    const token = localStorage.getItem("novabrains-token");
    const apiUrl = `${process.env.REACT_APP_API_SEND_PAYMENT_COMPLETE_CONFIRMATION}${sectionId}`;
    const response = await axios.get(apiUrl, {
      headers: { Authorization: `Token ${token}` },
    });
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(response.data.error);
    }
  } catch (error) {
    console.error("Error sending payment complete confirmation", error);
    throw error;
  }
};
