import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { Topbar, Sidebar, Footer } from "./components";
import LoadingScreen from "./components/LoadingScreen";
import { useNavigate } from "react-router-dom";
import { AppContext } from "contexts/AuthContext";

const Main = ({
                children,
                colorInvert = false,
                bgcolor = "rgba(255, 255, 255, 0.8)",
                showFooter = true,
              }) => {

    const { myCourses, handleLogout } = useContext(AppContext);
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up("md"), {
      defaultMatches: true,
    });

    // used by both Topbar and Sidebar
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [openSidebar, setOpenSidebar] = useState(false);

    const pages = {
      authenticated: [
        // {
        //   title: "My Quizzes",
        //   href: null,
        //   onClick: () => {
        //     navigate("/my-quizzes");
        //     handleSidebarClose();
        //   },
        // },
        // {
        //   title: "Profile",
        //   onClick: () => {
        //     navigate("/profile");
        //     handleSidebarClose();
        //   },
        // },
        {
          title: "Mis Cursos",
          href: null,
          landingPages: [],
        },
        {
          title: "Mi Perfil",
          landingPages: [
            {
              title: "Cerrar Sesión",
              href: null,
              onClick: () => {
                handleLogout();
              },
            }
          ],
        },
      ],
      unauthenticated: [
        {
          title: "Entrar",
          href: null,
          onClick: () => {
            navigate("/login");
            handleSidebarClose();
          },
          hideOnUrls: ["/login"],
        },
        {
          title: "Registrarse",
          onClick: () => {
            navigate("/signup");
            handleSidebarClose();
          },
          href: null,
          hideOnUrls: ["/signup"],
        },
      ],
      common: [
        // {
        //   title: "About Me",
        //   href: null,
        //   landingPages: [],
        // },
        {
          title: "Cursos",
          href: null,
          landingPages: [
            {
              title: "Tu Ciclo, Tu Poder",
              href: "/course/tu-ciclo",
            },
            {
              title: "Ciclo Consciente",
              href: "/course/ciclo-consciente",
            },
            {
              title: "Combo Ciclo Integral",
              href: "/course/combo-ciclo-integral",
            },
          ],
        },
        // {
        //   title: "Productos",
        //   href: null,
        //   landingPages: [
        //     {
        //       title: "Instrucciones",
        //       href: "/instrucciones",
        //     },
        //   ],
        // },
        // {
        //   title: "Store",
        //   href: null,
        //   landingPages: [],
        // }
      ],
    };

    // Add my courses to the pages authenticated (mis cursos) object
    if (myCourses) {
      myCourses.forEach((course) => {
        pages.authenticated[0].landingPages.push({
          title: course.course_title,
          href: `/view-course/${course.course_id}`,
        });
      });
    }

    const handleSidebarOpen = () => {
      setOpenSidebar(true);
    };

    const handleSidebarClose = () => {
      setOpenSidebar(false);
    };


    const open = isMd ? false : openSidebar;

    const trigger = useScrollTrigger({
      disableHysteresis: true,
      threshold: 38,
    });
    if (loading) {
      return <LoadingScreen/>;
    } else {
      return (
        <Box>
          <AppBar
            position={"sticky"}
            sx={{
              top: 0,
              backgroundColor: trigger ? theme.palette.background.paper : bgcolor,
              zIndex: 1040,
            }}
            elevation={1}
          >
            <Container maxWidth={"xl"}>
              <Topbar
                showLogin={false}
                onSidebarOpen={handleSidebarOpen}
                pages={pages}
                colorInvert={trigger ? false : colorInvert}
              />
            </Container>
          </AppBar>
          <Sidebar
            onClose={handleSidebarClose}
            open={open}
            variant="temporary"
            colorInvert={colorInvert}
            pages={pages}
          />
          {<main>{children}</main>}

          {showFooter && (
            <Container>
              <Footer showLogin={false}/>
            </Container>
          )}
        </Box>
      );
    }
  }
;

Main.propTypes = {
  children: PropTypes.node,
  colorInvert: PropTypes.bool,
  bgcolor: PropTypes.string,
};

export default Main;
