import React from 'react';
import { useTheme } from '@mui/material/styles';

import Container from "@mui/material/Container";
import { Hero, Main as MainSection, Partners, Contact } from './components';

const PortfolioGrid = () => {
  const theme = useTheme();
  return (
    <div style={{ marginTop: '20px' }}>
      <Container>
        <MainSection/>
      </Container>
    </div>
  );
};

export default PortfolioGrid;
