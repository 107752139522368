import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from '@mui/material/styles';
import getTheme from "components/theme";

import Main from "layouts/Main";
import Home from "./pages/Home/Home";
import SignInForm from "./pages/Login/SignInFormWithCoverImage";
import SignUpFormWithCoverImage from "./pages/SignUp/SignUpFormWithCoverImage";
import PortfolioGrid from "./pages/PortfolioGrid";
import CourseTuCirclo from "./pages/CourseTuCirclo";
import CicloConsciente from "./pages/CicloConsciente";
import Combo from "./pages/Combo";
import StripeCheckout from "./pages/StripeCheckout/StripeCheckout";
import PaymentComplete from "./pages/StripeCheckout/PaymentComplete";
import CourseNavigation from "./pages/ViewCourse/CourseNavigation";

import { AppProvider } from "./contexts/AuthContext";

const mode = 'light'; // This could be dynamic based on user preference or system settings
const theme = getTheme(mode); // Pass 'light' or 'dark' based on the preferred theme


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <AppProvider>
        <BrowserRouter>
          <Routes>
            <Route path={"/"} element={<Main><Home/></Main>}/>
            <Route path={"/login"} element={<Main><SignInForm/></Main>}/>
            <Route path={"/signup"} element={<Main><SignUpFormWithCoverImage/></Main>}/>
            <Route path={"/instrucciones"} element={<Main><PortfolioGrid/></Main>}/>
            <Route path={"/course/Tu-Ciclo"} element={<Main><CourseTuCirclo/></Main>}/>
            <Route path={"/course/Ciclo-Consciente"} element={<Main><CicloConsciente/></Main>}/>
            <Route path={"/course/Combo-Ciclo-Integral"} element={<Main><Combo/></Main>}/>
            <Route path={"/checkout/:courseId"} element={<Main><StripeCheckout/></Main>}/>
            <Route path={"/view-course/:courseId"} element={<Main><CourseNavigation/></Main>}/>
            <Route path={"/payment-complete"} element={<Main><PaymentComplete/></Main>}/>
          </Routes>
        </BrowserRouter>
      </AppProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
