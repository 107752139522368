import React, { useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useContext } from "react";
import { useLocation } from "react-router-dom";
import { AccountCircle } from "@mui/icons-material";
import NavItem from "./components/NavItem";
import { AppContext } from "contexts/AuthContext";

const SidebarNav = ({ pages, colorInvert = false }) => {
  const { isAuthenticated, mobileOpen, setMobileOpen } = useContext(AppContext);
  const location = useLocation();

  return (
    <Box>
      <Box width={1} paddingX={2} paddingY={1}>
        <Box
          display={"flex"}
          component="a"
          href="/"
          title="theFront"
          width={{ xs: 100, md: 120 }}
        >
          {/*<Box*/}
          {/*  component={"img"}*/}
          {/*  src={NovabrainsLogo}*/}
          {/*  alt="NovaBrains.ai logo"*/}
          {/*  height={1}*/}
          {/*  width={1}*/}
          {/*/>*/}
        </Box>
      </Box>
      <Box paddingX={2} paddingY={2}>
        {pages.common.map((page, i) => (
          page.title && page.landingPages && (
            <Box key={i}>
              <NavItem
                title={page.title}
                fullWidth
                href={page.href}
                id={page.title}
                items={page.landingPages}
              ></NavItem>
            </Box>)

        ))}
        {!isAuthenticated &&
          pages.unauthenticated.map((page, i) => {
            if (!page.hideOnUrls.includes(location.pathname)) {
              return (
                <Box key={i}>
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={page.onClick}
                    size="large"
                    color="primary"
                  >
                    {page.title}
                  </Button>
                </Box>
              );
            }
          })}
        {isAuthenticated && pages.authenticated.map((currPage, index) => (
          <Box
            key={index}
          >
            <NavItem
              title={currPage.title}
              href={currPage.href}
              onClick={currPage.onClick}
              id={currPage.title}
              items={currPage.landingPages}
              colorInvert={colorInvert}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

SidebarNav.propTypes = {
  pages: PropTypes.shape({
    common: PropTypes.arrayOf(PropTypes.object),
    authenticated: PropTypes.arrayOf(PropTypes.object),
    authenticatedMyTextbooks: PropTypes.arrayOf(PropTypes.object),
    unauthenticated: PropTypes.arrayOf(PropTypes.object),
  }),
  colorInvert: PropTypes.bool,
};

export default SidebarNav;
