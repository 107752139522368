/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

const Form = () => {
  const [errorMessage, setErrorMessage] = React.useState('');
  const navigate = useNavigate();
  const handleSubmit = (event) => {
    event.preventDefault();
    axios.post('https://api.usalacopa.com/login/', {
      email: event.target.email.value,
      password: event.target.password.value
    })
      .then((response) => {
        localStorage.setItem('usalacopa-token', response.data.token);
        window.location.href = '/';
      })
      .catch((error) => {
        setErrorMessage(error.response.data.detail);
        console.log(error);
      });
  }

  return (
    <Box>
      {errorMessage && (
        <Alert severity="error" style={{ marginBottom: "16px" }}>
          {errorMessage}
        </Alert>
      )}
      <Box marginBottom={4}>
        <Typography
          sx={{
            textTransform: 'uppercase',
          }}
          gutterBottom
          color={'text.secondary'}
          fontWeight={700}
        >
          Iniciar sesión
        </Typography>
        <Typography
          variant="h4"
          sx={{
            fontWeight: 700,
          }}
        >
          Bienvenido de nuevo
        </Typography>
        <Typography color="text.secondary">
          Inicie sesión para gestionar su cuenta.
        </Typography>
      </Box>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Introduzca su correo electrónico
            </Typography>
            <TextField
              label="Correo electrónico"
              variant="outlined"
              name={'email'}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Box
              display="flex"
              flexDirection={{ xs: 'column', sm: 'row' }}
              alignItems={{ xs: 'stretched', sm: 'center' }}
              justifyContent={'space-between'}
              width={1}
              marginBottom={2}
            >
              <Box marginBottom={{ xs: 1, sm: 0 }}>
                <Typography variant={'subtitle2'}>
                  Introduzca su contraseña
                </Typography>
              </Box>
              <Typography variant={'subtitle2'}>
                <Link
                  component={'a'}
                  color={'primary'}
                  href={'#'}
                  underline={'none'}
                >
                  ¿Olvidó su contraseña?
                </Link>
              </Typography>
            </Box>
            <TextField
              label="Contraseña"
              variant="outlined"
              name={'password'}
              type={'password'}
              fullWidth
              required
            />
          </Grid>
          <Grid item container xs={12}>
            <Box
              display="flex"
              flexDirection={{ xs: 'column', sm: 'row' }}
              alignItems={{ xs: 'stretched', sm: 'center' }}
              justifyContent={'space-between'}
              width={1}
              maxWidth={600}
              margin={'0 auto'}
            >
              <Box marginBottom={{ xs: 1, sm: 0 }}>
                <Typography variant={'subtitle2'}>
                  ¿No tiene una cuenta aún?{' '}
                  <Link
                    component={'a'}
                    color={'primary'}
                    href={'#'}
                    underline={'none'}
                  >
                    Regístrese aquí.
                  </Link>
                </Typography>
              </Box>
              <Button size={'large'} variant={'contained'} type={'submit'}>
                Iniciar sesión
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default Form;
