import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import AccessTimeTwoToneIcon from '@mui/icons-material/AccessTimeTwoTone';

import Course01 from "images/course01.jpg";
import Course02 from "images/course02.jpg";
import Course03 from "images/course03.jpg";

const mock = [
  {
    media: Course01,
    title: "Tu Ciclo, Tu Poder",
    description: 'Es un curso diseñado para entender y vivir en armonía con el ciclo menstrual ovulatorio. Ofrece herramientas para registrar y comprender la ciclicidad a través de la ciencia y la magia. Descubre y celebra tu ciclo, aprendiendo a vivir en sintonía con sus ritmos naturales y únicos.',
    price: '$150.000 COP',
    discountPrice: '$120.000 COP (-20%)',
    size: '2.400',
    duration: "2 hours and 30 minutes",
    href: '/course/tu-ciclo',
  },
  {
    media: Course02,
    title: "Ciclo Consciente",
    description: "Este curso está diseñado para aprender desde cero el método sintotérmico, una práctica que te permite gestionar tu fertilidad de manera natural y consciente, combinando ciencia y autoconocimiento. Domina tu salud reproductiva y ciclos con confianza y autonomía, abrazando cada fase de tu ciclo.",
    duration: "3 hours and 45 minutes",
    price: '$250.000 COP',
    discountPrice: '$200.000 COP (-20%)',
    size: '2.800',
    href: '/course/ciclo-consciente',
  },
  {
    media: Course03,
    title: 'Combo Ciclo Integral',
    description: "Nuestros cursos \"Tu Ciclo, Tu Poder\" y \"Ciclo Consciente\" están diseñados para guiarte en un viaje de autoconocimiento profundo de tu ciclo menstrual ovulatorio. Adquiere conocimientos y técnicas de registro y únete a una comunidad que apoya cada paso hacia tu consciencia cíclica.",
    price: '$400.000 COP',
    discountPrice: '$300.000 COP (-25%)',
    duration: "2 hours and 15 minutes",
    href: '/course/combo-ciclo-integral',
  }
];

const FeaturedProperties = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box paddingBottom={4} paddingTop={4}>
      <Box marginBottom={4}>
        <Typography
          variant="h4"
          align={'center'}
          data-aos={'fade-up'}
          gutterBottom
          sx={{
            fontWeight: 700,
          }}
        >
          Cursos
        </Typography>
        {/*<Typography*/}
        {/*  variant="h6"*/}
        {/*  align={'center'}*/}
        {/*  color={'text.secondary'}*/}
        {/*  data-aos={'fade-up'}*/}
        {/*>*/}
        {/*  Explore Our Transformative Learning Journey.*/}
        {/*  <br/>*/}
        {/*  Dive into our curated collection of courses designed to enlighten and empower.*/}
        {/*</Typography>*/}
      </Box>
      <Grid container spacing={4}>
        {mock.map((item, i) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            key={i}
            data-aos={'fade-up'}
            data-aos-delay={i * 100}
            data-aos-offset={100}
            data-aos-duration={600}
          >
            <Box display={'block'} width={1} height={1}>
              <Box
                component={Card}
                width={1}
                height={1}
                display={'flex'}
                flexDirection={'column'}
              >
                <CardMedia
                  Make AccessTimeTwoToneIcon smallertitle={item.title}
                  image={item.media}
                  sx={{
                    position: 'relative',
                    height: { xs: 240, sm: 340, md: 280 },
                    overflow: 'hidden',
                  }}
                >
                  <Box
                    display={'flex'}
                    position={'absolute'}
                    bottom={0}
                    padding={2}
                    width={1}
                  >
                    <Box
                      padding={1}
                      bgcolor={'background.paper'}
                      boxShadow={1}
                      marginRight={2}
                      borderRadius={2}
                    >
                      <Typography sx={{ fontWeight: 600, textDecoration: 'line-through' }}>
                        {item.price}
                      </Typography>
                    </Box>

                    <Box
                      padding={1}
                      bgcolor={'background.paper'}
                      boxShadow={1}
                      borderRadius={2}
                    >
                      <Typography sx={{ fontWeight: 600 }}>
                        {item.discountPrice}
                      </Typography>
                    </Box>

                  </Box>
                </CardMedia>

                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography
                    variant={'h6'}
                    align={'justify'}
                    sx={{ fontWeight: 700 }}
                  >
                    {item.title}
                  </Typography>

                  <Box display={'flex'} alignItems={'center'}>

                    <Typography variant={'subtitle2'} align={'justify'} color="text.secondary">
                      {item.description}
                    </Typography>
                  </Box>

                  {/*<Box display={'flex'} alignItems={'center'} marginTop={2}>*/}
                  {/*  <AccessTimeTwoToneIcon style={{ fontSize: 16, marginRight: '5px' }}/>*/}
                  {/*  <Typography variant={'subtitle2'} color="text.secondary">*/}
                  {/*    {item.duration}*/}
                  {/*  </Typography>*/}
                  {/*</Box>*/}
                </CardContent>
                <CardActions sx={{ justifyContent: 'flex-end' }}>
                  <Button
                    href={item.href}
                    endIcon={
                      <Box
                        // add an onclick event to the button using href
                        component={'svg'}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        width={24}
                        height={24}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M17 8l4 4m0 0l-4 4m4-4H3"
                        />
                      </Box>
                    }
                  >
                    Me interesa
                  </Button>
                </CardActions>
              </Box>
            </Box>
          </Grid>
        ))}
        {/*<Grid item xs={12}>*/}
        {/*  <Box*/}
        {/*    display="flex"*/}
        {/*    flexDirection={{ xs: 'column', sm: 'row' }}*/}
        {/*    alignItems={{ xs: 'stretched', sm: 'flex-start' }}*/}
        {/*    justifyContent={'center'}*/}
        {/*    marginTop={2}*/}
        {/*  >*/}
        {/*    <Box*/}
        {/*      component={Button}*/}
        {/*      variant="outlined"*/}
        {/*      color="primary"*/}
        {/*      size="large"*/}
        {/*      marginTop={{ xs: 2, sm: 0 }}*/}
        {/*      marginLeft={{ sm: 2 }}*/}
        {/*      fullWidth={isMd ? false : true}*/}
        {/*    >*/}
        {/*      See All Available Courses*/}
        {/*    </Box>*/}
        {/*  </Box>*/}
        {/*</Grid>*/}
      </Grid>
    </Box>
  );
};

export default FeaturedProperties;
