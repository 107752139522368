import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import CardMedia from "@mui/material/CardMedia";


import Course02 from "images/course02.jpg";

const Main = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const sendMessageToWhatsApp = () => {
    const phoneNumber = '573043617364'; // Replace with the actual phone number
    const message = encodeURIComponent('Estoy interesada en el curso "Ciclo Consciente: La Magia y la Ciencia Detrás del Método Sintotérmico"');
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${message}`;

    window.open(whatsappUrl, '_blank'); // Open WhatsApp web/app
  };

  const goToCheckout = () => {
    navigate('/checkout/e88b1c69-702f-4511-917d-82ed98056458'); // Navigate programmatically
  };

  return (
    <Box>
      <Grid container spacing={isMd ? 4 : 2}>
        <Grid item xs={12} md={7}>
          <Box marginBottom={3}>
            <Box style={{ textAlign: "center" }}>
              <Typography fontWeight={700} variant={'h4'} gutterBottom style={{ marginBottom: 0 }}>
                Ciclo Consciente
              </Typography>
            </Box>
            <Divider sx={{ marginY: 2 }}/>
            <Typography variant={'h5'} fontWeight={700} gutterBottom>
              Ciclo Consciente: La magia y la ciencia detrás del Método Sintotérmico
            </Typography>
            <Typography component={'p'} align={"justify"}>
              Este taller avanzado es ideal para ti si buscas profundizar en el método sintotérmico y quieres ser autónoma en la gestión de tu
              fertilidad.Explorar los biomarcadores de tu ciclo menstrual ovulatorio y dominar la ciencia detrás de la planificación natural.
              Aprenderás la técnica detallada para monitorear signos de fertilidad y hacer registros precisos que te permitirán interpretar con confianza los
              patrones de tu ciclo.
            </Typography>
          </Box>
          <Box marginBottom={3}>
            <Typography variant={'h5'} fontWeight={700} gutterBottom>
              En Ciclo Consciente, abordaremos:
            </Typography>
            <Grid container spacing={1} sx={{ marginTop: 1 }}>
              {[
                'Métodos de Registro',
                'Detección de la Fertilidad',
                'Interpretación de las fases de tu ciclo',
                'Ciencia Aplicada al uso de la temperatura basal y la observación del comportamiento bifásico para gestionar tu fertilidad.'
              ].map((item, i) => (
                <Grid item xs={12} key={i}>
                  <Box
                    component={ListItem}
                    disableGutters
                    width={'auto'}
                    padding={0}
                  >
                    <Box
                      component={ListItemAvatar}
                      minWidth={'auto !important'}
                      marginRight={2}
                    >
                      <Box
                        component={Avatar}
                        bgcolor={theme.palette.secondary.main}
                        width={20}
                        height={20}
                      >
                        <svg
                          width={12}
                          height={12}
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </Box>
                    </Box>
                    <ListItemText primary={item}/>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>

          <Box>
            <Typography variant={'h5'} fontWeight={700} gutterBottom>
              Lograrás:
            </Typography>
            <Box marginBottom={3}>
              <Grid container spacing={1} sx={{ marginTop: 1 }}>
                {[
                  'Una habilidad refinada para reconocer e interpretar las señales de tu cuerpo',
                  'Conocimientos prácticos para aplicar el método sintotérmico con una eficacia de hasta el 99%',
                  'Autonomía para tomar decisiones informadas sobre tu salud reproductiva, sin depender de aplicaciones, dispositivos y hormonas sintéticas',
                ].map((item, i) => (
                  <Grid item xs={12} key={i}>
                    <Box
                      component={ListItem}
                      disableGutters
                      width={'auto'}
                      padding={0}
                    >
                      <Box
                        component={ListItemAvatar}
                        minWidth={'auto !important'}
                        marginRight={2}
                      >
                        <Box
                          component={Avatar}
                          bgcolor={theme.palette.secondary.main}
                          width={20}
                          height={20}
                        >
                          <svg
                            width={12}
                            height={12}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </Box>
                      </Box>
                      <ListItemText primary={item} primaryTypographyProps={{ align: "justify" }}/>
                    </Box>
                  </Grid>
                ))}
              </Grid>

            </Box>
            <Box marginBottom={2}>
              <Typography component={'p'} align={"justify"}>
                Este taller es para ti si estás comprometida a entender tu cuerpo a fondo y deseas vivir en armonía con tu fertilidad. Sea que quieras evitar el
                embarazo naturalmente o estés buscando concebir, este curso te dará las herramientas y la confianza para gestionar tu ciclo de manera efectiva.
              </Typography>
            </Box>
          </Box>
          <Box>
            <Typography variant={'h5'} fontWeight={700} gutterBottom>
              Qué incluye:
            </Typography>
            <Box marginBottom={3}>
              <Grid container spacing={1} sx={{ marginTop: 1 }}>
                {[
                  'Lecciones pregrabadas en profundidad sobre cada aspecto del método sintotérmico, que podrás ver a tu tiempo',
                  'Guías, gráficos y tablas detalladas para registrar tu ciclo con precisión',
                  'Meditación para conectar con tu útero',
                  'Acceso a una comunidad de apoyo donde podrás compartir tus experiencias, aclarar dudas y fortalecer tu aprendizaje',
                ].map((item, i) => (
                  <Grid item xs={12} key={i}>
                    <Box
                      component={ListItem}
                      disableGutters
                      width={'auto'}
                      padding={0}
                    >
                      <Box
                        component={ListItemAvatar}
                        minWidth={'auto !important'}
                        marginRight={2}
                      >
                        <Box
                          component={Avatar}
                          bgcolor={theme.palette.secondary.main}
                          width={20}
                          height={20}
                        >
                          <svg
                            width={12}
                            height={12}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </Box>
                      </Box>
                      <ListItemText primary={item} primaryTypographyProps={{ align: "justify" }}/>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>

            <Typography component={'p'}>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={5} style={{ position: 'sticky', top: 0 }}>
          <Box>
            <Box component={Card}>
              <CardMedia
                Make AccessTimeTwoToneIcon smallertitle="AB"
                image={Course02}
                sx={{
                  position: 'relative',
                  height: { xs: 240, sm: 340, md: 280 },
                  overflow: 'hidden',
                }}
              >
                <Box
                  display={'flex'}
                  position={'absolute'}
                  bottom={0}
                  padding={2}
                  width={1}
                >
                  <Box
                    padding={1}
                    marginRight={2}
                    bgcolor={'background.paper'}
                    boxShadow={1}
                    borderRadius={2}
                  >
                    <Typography sx={{ fontWeight: 600, textDecoration: 'line-through' }}>
                      $250.000 COP
                    </Typography>
                  </Box>
                  <Box
                    padding={1}
                    bgcolor={'background.paper'}
                    boxShadow={1}
                    borderRadius={2}
                  >
                    <Typography sx={{ fontWeight: 600 }}>
                      $200.000 COP (-20%)
                    </Typography>
                  </Box>
                </Box>
              </CardMedia>
            </Box>
          </Box>
          <Grid container spacing={isMd ? 4 : 2} direction="column">
            <Grid item xs={12} data-aos="fade-up">
              <Box component={Card}>
                <CardContent style={{ textAlign: "center" }}>
                  <Typography variant="h6" gutterBottom color="text.primary" fontWeight={700} textAlign={"center"}>
                    Quiero transformar mi experiencia cíclica
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Button
                        onClick={sendMessageToWhatsApp}
                        variant="contained"
                        color="primary"
                        size="large"
                        fullWidth
                        endIcon={
                          <Box
                            component={'svg'}
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            width={24}
                            height={24}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M17 8l4 4m0 0l-4 4m4-4H3"
                            />
                          </Box>
                        }
                      >
                        Estoy Lista
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={6}> {/* Another half for the second button */}
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        fullWidth
                        endIcon={
                          <Box
                            component={'svg'}
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            width={24}
                            height={24}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M17 8l4 4m0 0l-4 4m4-4H3"
                            />
                          </Box>
                        }
                        disabled={true}  // This makes the button disabled
                      >
                        Lo Quiero
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Main;
