import React, { useState, useEffect } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useTheme } from "@mui/material/styles";

import StripeCheckoutForm from "./components/StripeCheckoutForm";
import "./StripeCheckout.css";
import { createStripePaymentIntent } from "api/stripe";
// import { getCourseSectionByAccessCode } from "api/userAPI";
import { notifyFailure } from "toastNotifications";
import { Box, Grid, Typography, Divider, Button, Container } from "@mui/material";
import StripeLogo from "images/stripe-logo.png";
import { CheckCircle, Home } from "@mui/icons-material";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function formatPrice(priceInCents) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'COP',
  });

  return formatter.format(priceInCents / 100);  // Convert cents to dollars
}


const StripeCheckout = () => {
  console.log(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
  console.log(stripePromise);

  const theme = useTheme();
  const navigate = useNavigate();
  const [clientSecret, setClientSecret] = useState("");
  const [coursePrice, setCoursePrice] = useState("");
  const [courseTitle, setCourseTitle] = useState("");

  const { search } = useLocation();
  const [sectionId, setSectionId] = useState(null);
  const [accessCode, setAccessCode] = useState(null);
  const [sectionDetails, setSectionDetails] = useState(null);
  const [alreadyEnrolled, setAlreadyEnrolled] = useState(false);

  const { courseId } = useParams();
  console.log("courseId: ", courseId);

  // useEffect(() => {
  //   if (sectionId) {
  //     checkIfAlreadyEnrolledInSection({ sectionId: sectionId })
  //       .then((already_enrolled) => {
  //         if (already_enrolled) {
  //           setAlreadyEnrolled(true);
  //         }
  //       })
  //       .catch((e) => {
  //         console.error(
  //           "Error checking if already enrolled in course section: ",
  //           e,
  //         );
  //         notifyFailure("Error checking if already enrolled in course section");
  //       });
  //   }
  // }, [sectionId]);

  // useEffect(() => {
  //   // get the section details and enroll
  //   if (accessCode) {
  //     // fetch section details
  //     getCourseSectionByAccessCode({ accessCode: accessCode })
  //       .then((data) => {
  //         setSectionDetails(data.sections[0]);
  //       })
  //       .catch((e) => {
  //         console.error("Failed to find course section: ", e);
  //         notifyFailure("Failed to find course section");
  //       });
  //   }
  // }, [accessCode]);

  useEffect(() => {
    if (!alreadyEnrolled || true) {
      // Create PaymentIntent as soon as the page loads
      createStripePaymentIntent({ courseId })
        .then((response) => {
          console.log("response: ", response);
          setClientSecret(response.clientSecret);
          setCoursePrice(response.coursePrice);  // Assuming API response includes coursePrice
          setCourseTitle(response.courseTitle);  // Assuming API response includes courseTitle
        })
        .catch((error) => {
          notifyFailure("Error initializing checkout; please refresh the page and try again", error);
          console.error("Error creating stripe payment intent", error);
        });
    }
  }, [sectionDetails]);

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };
  //
  // if (!sectionDetails) {
  //   return <div>Loading</div>;
  // }
  if (!alreadyEnrolled && clientSecret || true) {
    return (
      <Container maxWidth={"md"} style={{ marginTop: '40px' }}>
        <Grid container spacing={6}>
          <Grid item xs={12} md={6}>
            <Box
              display="flex"
              justifyContent="center"
              flexDirection={"column"}
            >
              <Typography
                variant="h5"
                align="left"
                color="text.primary"
                marginBottom={1}
                sx={{ fontWeight: 700 }}
              >
                {courseTitle}: {formatPrice(coursePrice)}
              </Typography>
              <Divider
                sx={{
                  marginTop: "1rem",
                  marginBottom: "2rem",
                }}
              />
              <Typography variant="p" color={"text.primary"} style={{ textAlign: "justify" }}>
                La matriculación en el curso tiene un costo de {formatPrice(coursePrice)}. Por favor, proporcione su información de pago para proceder con la
                matrícula.
                Recibirá un
                recibo por correo electrónico una vez completado el proceso de pago. Su información de pago se procesa de manera segura con Stripe y no se
                almacena por nosotros. Si cree que ya pagó por este curso, envíenos un mensaje y le asistiremos con cualquier problema relacionado con la
                matriculación.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            {clientSecret && (
              console.log("optionsBBB: ", options),
                <Elements options={options} stripe={stripePromise}>
                  <StripeCheckoutForm
                    courseId={sectionId}
                  />
                </Elements>
            )}
          </Grid>
          <Divider/>
          <Grid
            item
            container
            justifyContent="center"
            alignItems="center"
            xs={12}
          >
            <Box
              maxWidth={"lg"}
              justifyContent={"center"}
              display="flex"
              width={1}
            >
              <Box
                component={"img"}
                alt="Stripe Logo"
                src={StripeLogo}
                width={"200px"}
                margin={"0 auto"}
                display={"flex"}
                alignItems={"center"}
                height={1}
                sx={{
                  borderRadius: 3,
                  filter:
                    theme.palette.mode === "dark" ? "brightness(0.8)" : "none",
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    );
  } else if (alreadyEnrolled) {
    return (
      <Container maxWidth={"md"}>
        <Grid container spacing={6}>
          <Grid item xs={12} justifyContent={"center"} display={"flex"}>
            <Box
              display="flex"
              justifyContent="center"
              maxWidth={"sm"}
              flexDirection={"column"}
            >
              <Typography
                variant="h4"
                align="center"
                color="primary"
                marginBottom={1}
                sx={{ fontWeight: 700 }}
              >
                Already Enrolled <CheckCircle/>
              </Typography>

              <Typography
                variant="p"
                color={"text.primary"}
                textAlign={"center"}
              >
                You are already enrolled in {sectionDetails.course_title}{" "}
                Section {sectionDetails.number}, so there's no need to pay to
                enroll!
              </Typography>

              <Divider
                sx={{
                  marginTop: "1rem",
                  marginBottom: "2rem",
                }}
              />

              <Button
                onClick={() => navigate("/")}
                variant="outlined"
                color="secondary"
                startIcon={<Home/>}
              >
                Back to Home
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
    );
  }
};

export default StripeCheckout;
