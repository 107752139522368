import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

const SuccessModal = ({
  isOpen,
  onClose,
  bodyText,
  buttonText = "Take me to the instructor page",
  titleText = "Success",
}) => {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "30%",
          bgcolor: "white",
          border: "1px solid #ccc",
          boxShadow: 3,
          borderRadius: "10px",
          p: 3,
        }}
      >
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{ textAlign: "center", marginBottom: 2, fontWeight: "bold" }}
        >
          {titleText}
        </Typography>
        <Typography
          id="modal-modal-description"
          sx={{ mt: 2, textAlign: "center", marginBottom: 2 }}
        >
          {bodyText}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={onClose}
          sx={{ mt: 3, display: "block", margin: "0 auto" }}
        >
          {buttonText}
        </Button>
      </Box>
    </Modal>
  );
};

export default SuccessModal;
