import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
  Box,
  Container,
  Button,
  Grid,
  Typography,
  Divider,
} from "@mui/material";
import { enrollInCourseSection } from "api/userAPI";
import { notifyFailure } from "toastNotifications";
import { sendPaymentCompleteConfirmation } from "api/stripe";

const PaymentComplete = () => {
  // get access code and section id from query parameters
  const { search } = useLocation();
  const navigate = useNavigate();
  const [sectionId, setSectionId] = useState(null);
  const [accessCode, setAccessCode] = useState(null);
  const [sectionDetails, setSectionDetails] = useState(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    const sectionIdQ = queryParams.get("sectionId");
    const accessCodeQ = queryParams.get("accessCode");
    if (sectionIdQ) {
      setSectionId(sectionIdQ);
    }
    if (accessCodeQ) {
      setAccessCode(accessCodeQ);
    }
  }, []);

  useEffect(() => {
    if (sectionId) {
      sendPaymentCompleteConfirmation({ sectionId: sectionId }).catch((e) => {
        console.error("Failed to send payment confirmation email: ", e);
      });
    }
  }, [sectionId]);

  return (
    <Container
      maxWidth="md"
      sx={{
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Box
            display="flex"
            justifyContent="center"
            flexDirection={"column"}
            maxWidth={"sm"}
          >
            <Typography
              variant="h4"
              align="left"
              color="text.primary"
              marginBottom={1}
              textAlign={"left"}
              sx={{ fontWeight: 700 }}
            >
              Payment Complete!
            </Typography>

            <Typography
              variant="p"
              color={"text.primary"}
              marginBottom={2}
              textAlign={"left"}
            >
              You're now enrolled in
            </Typography>
            <Typography
              variant="p"
              color={"text.primary"}
              marginBottom={2}
              textAlign={"left"}
            >
              You should receive a receipt for your records shortly at the email
              address you provided during checkout.
            </Typography>

            <Divider
              sx={{
                marginTop: "1rem",
                marginBottom: "2rem",
              }}
            />

            <Button
              variant="contained"
              color="secondary"
              sx={{ maxWidth: "fit-content" }}
              onClick={() => {
                navigate("/");
              }}
            >
              Back to Homepage
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PaymentComplete;
