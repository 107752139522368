// userAPI.js

import axios from "axios";

export const createUser = async (userData) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_API_SIGNUP,
      userData,
    );
    return response.data;
  } catch (error) {
    console.error("Error creating user", error);
    throw error;
  }
};

export const checkAuthWithToken = async (token) => {
  var response;
  try {
    response = await axios.get(process.env.REACT_APP_API_CHECK_AUTH, {
      headers: { Authorization: `Token ${token}` },
    });
    return response.status === 200;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      // invalid token. go ahead and clear it.
      console.error("Invalid token. Clearing local storage.");
      localStorage.clear();
    }
    return false;
  }
};

export const checkAuthStatus = async () => {
  const token = localStorage.getItem("usalacopa-token");
  if (!token) {
    return false;
  }
  return checkAuthWithToken(token);
};

export const getMyCourses = async () => {
  try {
    const token = localStorage.getItem("usalacopa-token");
    const response = await axios.get(process.env.REACT_APP_API_MY_COURSES, {
      headers: { Authorization: `Token ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error("Error getting my courses", error);
    throw error;
  }
}

export const userLogout = async () => {
  try {
    const token = localStorage.getItem("usalacopa-token");
    const response = await axios.post(
      process.env.REACT_APP_API_LOGOUT,
      {},
      {
        headers: { Authorization: `Token ${token}` },
      },
    );

    // if response is 204 or response is 401 (unauthorized, which means the token is already invalid), clear local storage.
    if (response.status === 204 || response.status === 401) {
      localStorage.clear();
      return response.data;
    }
    throw new Error("Error logging out");
  } catch (error) {
    console.error("Error logging out", error);
    // clear local storage if there's an error, best to force new login.
    localStorage.clear();
    throw error;
  }
};


export const getCourseNavigation = async (courseId) => {
  try {
    const token = localStorage.getItem("usalacopa-token");
    const response = await axios.get(
      `${process.env.REACT_APP_API_COURSE_NAVIGATION}${courseId}/`,
      {
        headers: { Authorization: `Token ${token}` },
      },
    );
    return response.data;
  } catch (error) {
    console.error("Error getting course navigation", error);
    throw error;
  }
}