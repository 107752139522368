import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import CardMedia from "@mui/material/CardMedia";


import Course01 from "images/course01.jpg";

const Main = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const sendMessageToWhatsApp = () => {
    const phoneNumber = '573043617364'; // Replace with the actual phone number
    const message = encodeURIComponent('Estoy interesada en el curso "Tu Ciclo, Tu Poder: Guía para el Autoconocimiento Femenino"');
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${message}`;

    window.open(whatsappUrl, '_blank'); // Open WhatsApp web/app
  };

  const goToCheckout = () => {
    navigate('/checkout/12c6a596-60b9-4298-87c3-b562146c2c68'); // Navigate programmatically
  };

  return (
    <Box>
      <Grid container spacing={isMd ? 4 : 2}>
        <Grid item xs={12} md={7}>
          <Box marginBottom={3}>
            <Box style={{ textAlign: "center" }}>
              <Typography fontWeight={700} variant={'h4'} gutterBottom style={{ marginBottom: 0 }}>
                Tu Ciclo, Tu Poder
              </Typography>
            </Box>
            <Divider sx={{ marginY: 2 }}/>
            <Typography variant={'h5'} fontWeight={700} gutterBottom>
              Guía para el Autoconocimiento Femenino
            </Typography>
            <Typography component={'p'} align={"justify"}>
              Este taller está especialmente diseñado para mujeres que desean reconectar con su cuerpo y entender los ritmos de su ciclo menstrual ovulatorio.
              "Tu Ciclo, Tu Poder" es una invitación a adentrarte en la sabiduría de tu ser, ofreciendo conocimiento y herramientas prácticas desde la ciencia y
              la magia para mujeres que están listas para abrazar su ciclicidad con amor y admiración.
            </Typography>
          </Box>
          <Box marginBottom={3}>
            <Typography variant={'h5'} fontWeight={700} gutterBottom>
              A lo largo de este programa descubrirás:
            </Typography>
            <Grid container spacing={1} sx={{ marginTop: 1 }}>
              {[
                'Fundamentos del Ciclo Menstrual Ovulatorio',
                'La Magia de Ser Cíclica',
                'Seguimiento y Registro del Ciclo',
                'Vitalidad a Través del Ciclo'
              ].map((item, i) => (
                <Grid item xs={12} key={i}>
                  <Box
                    component={ListItem}
                    disableGutters
                    width={'auto'}
                    padding={0}
                  >
                    <Box
                      component={ListItemAvatar}
                      minWidth={'auto !important'}
                      marginRight={2}
                    >
                      <Box
                        component={Avatar}
                        bgcolor={theme.palette.secondary.main}
                        width={20}
                        height={20}
                      >
                        <svg
                          width={12}
                          height={12}
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </Box>
                    </Box>
                    <ListItemText primary={item}/>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
          <Box marginBottom={3}>
            <Typography component={'p'} align={"justify"}>
              Al finalizar este taller, no solo te llevarás un entendimiento enriquecido de tu ciclo menstrual ovulatorio, sino que también desarrollarás la
              capacidad de aplicar este conocimiento de manera práctica, logrando una vida más plena y consciente. Este taller es para ti si estás lista para
              pasar de la confusión a la claridad y de la preocupación al empoderamiento.
            </Typography>
          </Box>
          <Box>
            <Typography variant={'h5'} fontWeight={700} gutterBottom>
              Qué incluye:
            </Typography>
            <Box marginBottom={3}>
              <Grid container spacing={1} sx={{ marginTop: 1 }}>
                {[
                  'Clases pregrabadas para que aprendas a tu ritmo',
                  'Gráfico de registro para empezar a hacer un seguimiento de tu ciclo a nivel biológico y energético',
                  'Meditación para conectar con tu útero',
                  'Meditación para conectar con las mujeres que te habitan',
                  'Acceso gratis a un círculo de mujeres para compartir experiencias y preguntas',
                ].map((item, i) => (
                  <Grid item xs={12} key={i}>
                    <Box
                      component={ListItem}
                      disableGutters
                      width={'auto'}
                      padding={0}
                    >
                      <Box
                        component={ListItemAvatar}
                        minWidth={'auto !important'}
                        marginRight={2}
                      >
                        <Box
                          component={Avatar}
                          bgcolor={theme.palette.secondary.main}
                          width={20}
                          height={20}
                        >
                          <svg
                            width={12}
                            height={12}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </Box>
                      </Box>
                      <ListItemText primary={item} primaryTypographyProps={{ align: "justify" }}/>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>

            <Typography component={'p'}>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={5} style={{ position: 'sticky', top: 0 }}>
          <Box>
            <Box component={Card}>
              <CardMedia
                Make AccessTimeTwoToneIcon smallertitle="AB"
                image={Course01}
                sx={{
                  position: 'relative',
                  height: { xs: 240, sm: 340, md: 280 },
                  overflow: 'hidden',
                }}
              >
                <Box
                  display={'flex'}
                  position={'absolute'}
                  bottom={0}
                  padding={2}
                  width={1}
                >
                  <Box
                    padding={1}
                    marginRight={2}
                    bgcolor={'background.paper'}
                    boxShadow={1}
                    borderRadius={2}
                  >
                    <Typography sx={{ fontWeight: 600, textDecoration: 'line-through' }}>
                      $150.000 COP
                    </Typography>
                  </Box>
                  <Box
                    padding={1}
                    bgcolor={'background.paper'}
                    boxShadow={1}
                    borderRadius={2}
                  >
                    <Typography sx={{ fontWeight: 600 }}>
                      $120.000 COP (-20%)
                    </Typography>
                  </Box>
                </Box>
              </CardMedia>
            </Box>
          </Box>
          <Grid container spacing={isMd ? 4 : 2} direction="column">
            <Grid item xs={12} data-aos="fade-up">
              <Box component={Card}>
                <CardContent style={{ textAlign: "center" }}>
                  <Typography variant="h6" gutterBottom color="text.primary" fontWeight={700} textAlign={"center"}>
                    Quiero transformar mi experiencia cíclica
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Button
                        onClick={sendMessageToWhatsApp}
                        variant="contained"
                        color="primary"
                        size="large"
                        fullWidth
                        endIcon={
                          <Box
                            component={'svg'}
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            width={24}
                            height={24}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M17 8l4 4m0 0l-4 4m4-4H3"
                            />
                          </Box>
                        }
                      >
                        Estoy Lista
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={6}> {/* Another half for the second button */}
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        fullWidth
                        endIcon={
                          <Box
                            component={'svg'}
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            width={24}
                            height={24}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M17 8l4 4m0 0l-4 4m4-4H3"
                            />
                          </Box>
                        }
                        disabled={true}  // This makes the button disabled
                      >
                        Lo Quiero
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Main;
