import Features from "./Features";
import FeaturedProperties from "./FeaturedProperties";
import AboutMe from "./AboutMe";

import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import React from "react";

const Home = () => {
  return (
    <Box marginTop={2}>
      <Container style={{ padding: '20px' }}>
        <Features/>
      </Container>
      <Divider/>
      <Box bgcolor={'alternate.main'}>
        <Container>
          <FeaturedProperties/>
        </Container>
      </Box>
      <Divider/>
      <Container style={{ padding: '20px' }}>
        <AboutMe/>
      </Container>
    </Box>
  )
    ;
}

export default Home;