import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  AppBar, Toolbar, Typography, Drawer, Box, List, ListItem,
  ListItemText, Collapse, IconButton, useTheme, useMediaQuery
} from '@mui/material';
import Alert from '@mui/material/Alert';
import { Menu as MenuIcon, ExpandLess, ExpandMore } from '@mui/icons-material';
import { getCourseNavigation } from 'api/userAPI';
import { AppContext } from "contexts/AuthContext";

function CourseNavigation() {
  const { mobileOpen, setMobileOpen } = useContext(AppContext);
  const { courseId } = useParams();
  const [courseNav, setCourseNav] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [showCookieBanner, setShowCookieBanner] = useState(true);

  useEffect(() => {
    getCourseNavigation(courseId).then(data => {
      setCourseNav(data.all_res);
      console.log(data.all_res);
    });
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://player.vimeo.com/api/player.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleClick = id => {
    setOpenId(openId === id ? null : id);
  };

  const [openId, setOpenId] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState(null);

  const handleLessonClick = videoUrl => {
    setSelectedVideo(videoUrl);
    if (isMobile) {
      setMobileOpen(false);
    }
  };

  const acceptCookies = () => {
    document.cookie = "consent=accepted;max-age=86400;path=/"; // Expire after 1 day
    setShowCookieBanner(false);
  };

  const cookieBanner = showCookieBanner && (
    <div style={{ position: 'fixed', bottom: 0, width: '100%', backgroundColor: 'lightgray', padding: '10px', textAlign: 'center' }}>
      <p>This website uses cookies to ensure you get the best experience on our website.</p>
      <button onClick={acceptCookies}>Accept Cookies</button>
    </div>
  );

  const drawer = (
    <List>
      {courseNav && courseNav.map((chapter) => (
        <React.Fragment key={chapter.id}>
          <ListItem button onClick={() => handleClick(chapter.id)}>
            <ListItemText primary={chapter.chapter_title}/>
            {openId === chapter.id ? <ExpandLess/> : <ExpandMore/>}
          </ListItem>
          <Collapse in={openId === chapter.id} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {chapter.lessons.map((lesson) => (
                <ListItem button key={lesson.id} sx={{ pl: 4 }} onClick={() => handleLessonClick(lesson.video)}>
                  <ListItemText primary={lesson.title}/>
                </ListItem>
              ))}
            </List>
          </Collapse>
        </React.Fragment>
      ))}
    </List>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <Drawer
        variant={isMobile ? "temporary" : "permanent"}
        open={isMobile ? mobileOpen : true}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true // Better open performance on mobile.
        }}
        sx={{
          width: 240,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: 240,
            boxSizing: 'border-box',
            marginTop: isMobile ? '0px' : '70px',
            height: isMobile ? '100%' : `calc(100% - 70px)`,
            zIndex: 100
          }
        }}
      >
        {drawer}
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3, marginTop: '0px' }}>
        {selectedVideo ? (
          <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
            <iframe
              src={selectedVideo}
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
              style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }}
              title="Video Lesson"
            ></iframe>
          </div>
        ) : (
          <Alert severity="info" style={{ backgroundColor: '#f7c4c5' }}> {/* Replace '#hexColor' with your specific color */}
            <Typography variant="body1">Seleccione una lección para ver el video.</Typography>
          </Alert>
        )}
      </Box>
    </Box>
  );
}

export default CourseNavigation;
