import React from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import CardMedia from "@mui/material/CardMedia";


import Course03 from "images/course03.jpg";

const Main = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const sendMessageToWhatsApp = () => {
    const phoneNumber = '573043617364'; // Replace with the actual phone number
    const message = encodeURIComponent('Estoy interesada en el combo "Ciclo Integral: De la Comprensión a la Maestría Cíclica"');
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${message}`;

    window.open(whatsappUrl, '_blank'); // Open WhatsApp web/app
  };

  const goToCheckout = () => {
    navigate('/checkout/41909c08-2234-4df5-96df-d948ac15d6b8'); // Navigate programmatically
  };

  return (
    <Box>
      <Grid container spacing={isMd ? 4 : 2}>
        <Grid item xs={12} md={7}>
          <Box marginBottom={3}>
            <Box style={{ textAlign: "center" }}>
              <Typography fontWeight={700} variant={'h4'} gutterBottom style={{ marginBottom: 0 }}>
                Combo Ciclo Integral
              </Typography>
            </Box>
            <Divider sx={{ marginY: 2 }}/>
            <Typography variant={'h5'} fontWeight={700} gutterBottom>
              De la Comprensión a la Maestría cíclica
            </Typography>
            <Typography component={'p'} align={"justify"}>
              Combina la sabiduría y la ciencia con nuestro paquete de cursos. "Tu Ciclo, Tu Poder" te inicia en los fundamentos del ciclo menstrual ovulatorio,
              sumergiéndote en la maravillosa experiencia de tu ciclicidad. Avanza a “Ciclo Consciente”, donde profundizamos en el método sintotérmico,
              aprendiendo a registrar y comprender los biomarcadores de tu fertilidad con herramientas precisas y técnicas detalladas.
            </Typography>
          </Box>
          <Box marginBottom={3}>
            <Typography variant={'h5'} fontWeight={700} gutterBottom>
              Ambos cursos ofrecen:
            </Typography>
            <Grid container spacing={1} sx={{ marginTop: 1 }}>
              {[
                'Clases pregrabadas que se adaptan a tu ritmo de vida',
                'Meditaciones y gráficos de registro para monitoreo completo',
                'Acceso a una comunidad amorosa para compartir y aprender',
              ].map((item, i) => (
                <Grid item xs={12} key={i}>
                  <Box
                    component={ListItem}
                    disableGutters
                    width={'auto'}
                    padding={0}
                  >
                    <Box
                      component={ListItemAvatar}
                      minWidth={'auto !important'}
                      marginRight={2}
                    >
                      <Box
                        component={Avatar}
                        bgcolor={theme.palette.secondary.main}
                        width={20}
                        height={20}
                      >
                        <svg
                          width={12}
                          height={12}
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </Box>
                    </Box>
                    <ListItemText primary={item}/>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
          <Box marginBottom={3}>
            <Typography component={'p'} align={"justify"}>
              Al inscribirte en ambos, estarás dando un paso hacia una comprensión integral de tu salud menstrual y reproductiva. Prepárate para pasar de la
              incertidumbre a la confianza y del aprendizaje a la autonomía en la gestión de tu fertilidad.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={5} style={{ position: 'sticky', top: 0 }}>
          <Box>
            <Box component={Card}>
              <CardMedia
                Make AccessTimeTwoToneIcon smallertitle="AB"
                image={Course03}
                sx={{
                  position: 'relative',
                  height: { xs: 240, sm: 340, md: 280 },
                  overflow: 'hidden',
                }}
              >
                <Box
                  display={'flex'}
                  position={'absolute'}
                  bottom={0}
                  padding={2}
                  width={1}
                >
                  <Box
                    padding={1}
                    marginRight={2}
                    bgcolor={'background.paper'}
                    boxShadow={1}
                    borderRadius={2}
                  >
                    <Typography sx={{ fontWeight: 600, textDecoration: 'line-through' }}>
                      $400.000 COP
                    </Typography>
                  </Box>
                  <Box
                    padding={1}
                    bgcolor={'background.paper'}
                    boxShadow={1}
                    borderRadius={2}
                  >
                    <Typography sx={{ fontWeight: 600 }}>
                      $300.000 COP (-25%)
                    </Typography>
                  </Box>
                </Box>
              </CardMedia>
            </Box>
          </Box>
          <Grid container spacing={isMd ? 4 : 2} direction="column">
            <Grid item xs={12} data-aos="fade-up">
              <Box component={Card}>
                <CardContent style={{ textAlign: "center" }}>
                  <Typography variant="h6" gutterBottom color="text.primary" fontWeight={700} textAlign={"center"}>
                    Quiero transformar mi experiencia cíclica
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Button
                        onClick={sendMessageToWhatsApp}
                        variant="contained"
                        color="primary"
                        size="large"
                        fullWidth
                        endIcon={
                          <Box
                            component={'svg'}
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            width={24}
                            height={24}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M17 8l4 4m0 0l-4 4m4-4H3"
                            />
                          </Box>
                        }
                      >
                        Estoy Lista
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={6}> {/* Another half for the second button */}
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        fullWidth
                        endIcon={
                          <Box
                            component={'svg'}
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            width={24}
                            height={24}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M17 8l4 4m0 0l-4 4m4-4H3"
                            />
                          </Box>
                        }
                        disabled={true}  // This makes the button disabled
                      >
                        Lo Quiero
                      </Button>

                    </Grid>
                  </Grid>
                </CardContent>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Main;
