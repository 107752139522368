import React, { useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import CardMedia from '@mui/material/CardMedia';


const Main = () => {
  const theme = useTheme();

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://player.vimeo.com/api/player.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Box>

      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={6}>
          <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
            <iframe
              src="https://player.vimeo.com/video/930118386?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
              style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
              title="Week11WednesdayPolished"
            ></iframe>
          </div>
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
            <iframe
              src="https://player.vimeo.com/video/930118458?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
              style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
              title="Week11WednesdayPolished"
            ></iframe>
          </div>
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
            <iframe
              src="https://player.vimeo.com/video/930122725?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
              style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
              title="Week11WednesdayPolished"
            ></iframe>
          </div>
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
            <iframe
              src="https://player.vimeo.com/video/930864247?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
              style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
              title="Week11WednesdayPolished"
            ></iframe>
          </div>
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
            <iframe
              src="https://player.vimeo.com/video/930859622?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
              style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
              title="Week11WednesdayPolished"
            ></iframe>
          </div>
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
            <iframe
              src="https://player.vimeo.com/video/930124375?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
              style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
              title="Week11WednesdayPolished"
            ></iframe>
          </div>
        </Grid>

      </Grid>
    </Box>
  );
};

export default Main;
