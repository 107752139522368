import React, { createContext, useState, useEffect } from "react";
import { checkAuthStatus, getMyCourses, userLogout } from "../api/userAPI";

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [showLogin, setShowLogin] = useState(true);
  const [showLogout, setShowLogout] = useState(false);
  const [username, setUsername] = useState(
    localStorage.getItem("novabrains-username"),
  );
  const [currentPage, setCurrentPage] = useState(null);
  const [userType, setUserType] = useState(null);
  const [myCourses, setMyCourses] = useState(null);
  const [selectedCourseId, setSelectedCourseId] = useState(null);
  const [selectedChapterId, setSelectedChapterId] = useState(null);
  const [selectedPageId, setSelectedPageId] = useState(null);
  const [courseNav, setCourseNav] = useState(null);
  const [openBookNavSidebar, setOpenBookNavSidebar] = useState(true);
  const [studentCourseSection, setStudentCourseSection] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthenticatedLoading, setIsAuthenticatedLoading] = useState(true);
  const [mobileOpen, setMobileOpen] = useState(false);

  useEffect(() => {
    checkAuthStatus()
      .then((isAuth) => {
        setIsAuthenticated(isAuth);
        setIsAuthenticatedLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsAuthenticatedLoading(false);
      });
  }, []);


  const handleLogout = () => {
    userLogout()
      .then(() => {
        setIsAuthenticated(false);
      })
      .then(() => {
        window.location.href = '/';
      })
      .catch(error => {
        console.error('Logout failed:', error);
      });
  };


  useEffect(() => {
    if (isAuthenticated) {
      getMyCourses().then((data) => {
        setMyCourses(data.courses);
      });
    }
  }, [isAuthenticated]);
  return (
    <AppContext.Provider
      value={{
        handleLogout,
        showLogin,
        setShowLogin,
        showLogout,
        setShowLogout,
        selectedCourseId,
        setSelectedCourseId,
        selectedChapterId,
        setSelectedChapterId,
        selectedPageId,
        setSelectedPageId,
        courseNav,
        setCourseNav,
        isAuthenticated,
        isAuthenticatedLoading,
        username,
        myCourses,
        setMyCourses,
        userType,
        currentPage,
        setCurrentPage,
        studentCourseSection,
        setStudentCourseSection,
        openBookNavSidebar,
        setOpenBookNavSidebar,
        mobileOpen,
        setMobileOpen,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
