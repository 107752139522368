import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import SuccessModal from "modals/SuccessModal";
import { createUser } from "api/userAPI";

const Form = () => {

    const [isSignupLoading, setIsSignupLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalBodyText, setModalBodyText] = useState("");
    const [modalButtonText, setModalButtonText] = useState("");
    const [modalTitleText, setModalTitleText] = useState("");
    const [modalOnCloseAction, setModalOnCloseAction] = useState(() => () => {
    });

    const setModalSettings = ({ title, body, buttonText, onCloseAction }) => {
      setModalTitleText(title);
      setModalBodyText(body);
      setModalButtonText(buttonText);
      setModalOnCloseAction(() => onCloseAction);
      setShowModal(true);
      setIsSignupLoading(false);
    };
    const navigate = useNavigate();

    const signupTryAgain = () => {
      setFormData({
        first_name: "",
        last_name: "",
        password: "",
        email: "",
      });
      setShowModal(false);
    };

    const [formData, setFormData] = useState({
      email: "",
      first_name: "",
      last_name: "",
      password: "",
    });

    const navigateToLogin = () => {
      setShowModal(false);
      navigate("/login");
    };
    // Create a function that sends a POST request to the server using axios with the information in teh form
    const handleSubmit = (event) => {
      event.preventDefault();
      createUser(formData)
        .then((data) => {
          // instructor account not self-activated so send them home.
          // students can activate with email activation so send to login page.
          setModalSettings({
            title: "El registro fue exitoso y serás redirigido a la página de inicio de sesión para ingresar.",
            body: data.message,
            buttonText: "¡Entendido!",
            onCloseAction: navigateToLogin,
          });
        })
        .catch((error) => {
          setModalSettings({
            title: "Algo salió mal durante el registro; por favor, inténtelo de nuevo pronto o contacte a hola@usalacopa.com",
            body: error.response.data.error,
            buttonText: "¡Entendido!",
            onCloseAction: signupTryAgain,
          });
        });
    }

    return (
      <Box>
        <Box marginBottom={4}>
          <Typography
            sx={{
              textTransform: 'uppercase',
            }}
            gutterBottom
            color={'text.secondary'}
            fontWeight={700}
          >
            Registrarse
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontWeight: 700,
            }}
          >
            Crear una cuenta
          </Typography>
          <Typography color="text.secondary">
            Complete el formulario para comenzar.
          </Typography>
        </Box>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
                Ingrese su nombre
              </Typography>
              <TextField
                onChange={(e) =>
                  setFormData((prevState) => ({
                    ...prevState,
                    first_name: e.target.value,
                  }))
                }
                label="Nombre"
                variant="outlined"
                name={'firstName'}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
                Ingrese su apellido
              </Typography>
              <TextField
                onChange={(e) =>
                  setFormData((prevState) => ({
                    ...prevState,
                    last_name: e.target.value,
                  }))
                }
                label="Apellido"
                variant="outlined"
                name={'lastName'}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
                Ingrese su correo electrónico
              </Typography>
              <TextField
                onChange={(e) =>
                  setFormData((prevState) => ({
                    ...prevState,
                    email: e.target.value,
                  }))
                }
                label="Correo electrónico"
                variant="outlined"
                name={'email'}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
                Ingrese su contraseña
              </Typography>
              <TextField
                onChange={(e) =>
                  setFormData((prevState) => ({
                    ...prevState,
                    password: e.target.value,
                  }))
                }
                label="Contraseña"
                variant="outlined"
                name={'password'}
                type={'password'}
                fullWidth
                required
              />
            </Grid>
            <Grid item container xs={12}>
              <Box
                display="flex"
                flexDirection={{ xs: 'column', sm: 'row' }}
                alignItems={{ xs: 'stretched', sm: 'center' }}
                justifyContent={'space-between'}
                width={1}
                maxWidth={600}
                margin={'0 auto'}
              >
                <Box marginBottom={{ xs: 1, sm: 0 }}>
                  <Typography variant={'subtitle2'}>
                    ¿Ya tiene una cuenta?{' '}
                    <Link
                      component={'a'}
                      color={'primary'}
                      href={'#'}
                      underline={'none'}
                    >
                      Iniciar sesión.
                    </Link>
                  </Typography>
                </Box>
                <Button size={'large'} variant={'contained'} type={"submit"}>
                  Registrarse
                </Button>
              </Box>
            </Grid>
            <Grid
              item
              container
              xs={12}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <Typography
                variant={'subtitle2'}
                color={'text.secondary'}
                align={'center'}
              >
                Al hacer clic en el botón "Registrarse" acepta nuestros{' '}
                <Link
                  component={'a'}
                  color={'primary'}
                  href={'#'}
                  underline={'none'}
                >
                  términos y condiciones de la empresa.
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </form>
        <SuccessModal
          bodyText={modalBodyText}
          titleText={modalTitleText}
          isOpen={showModal}
          onClose={modalOnCloseAction}
          buttonText={modalButtonText}
        />
      </Box>
    );
  }
;

export default Form;
