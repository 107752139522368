import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

const Footer = ({ showLogin }) => {
  const navigate = useNavigate();

  const location = useLocation();
  if (location.pathname.includes("view-course")) {
    return null;
  }

  return (
    <Grid container spacing={2} marginTop={4} marginBottom={4} borderTop={.5} borderColor={"lightgray"}>
      <Grid item xs={12}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          width={1}
          flexDirection={{ xs: "row" }}
        >
          <Box display={"flex"} title="NovaBrains home link">
            <Typography
              variant="h6"
              style={{
                display: "flex",
                cursor: "pointer",
                fontWeight: 700,
                fontFamily: '"Inter",sans-serif',
              }}
              onClick={() => navigate("/")}
            >
              <span style={{ color: '#c29c6f' }}>usalacopa</span>
              <span style={{ color: "#ad4b00" }}>.com</span>
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography
          align={"center"}
          variant={"subtitle2"}
          color="text.secondary"
          gutterBottom
        >
          &copy; usalacopa.com 2024. All rights reserved
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography
          align={"center"}
          variant={"caption"}
          color="text.secondary"
          component={"p"}
        >
          <a style={{ textDecoration: 'none' }} href="/privacy-policy" title="read the NovaBrains privacy policy">
            Privacy Policy
          </a>
          .
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography
          align={"center"}
          variant={"caption"}
          color="text.secondary"
          component={"p"}
        >
          <a style={{ textDecoration: 'none' }} href="/terms-of-use" title="read the NovaBrains terms of use">
            Terms of Use
          </a>
          .
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Footer;
