import React from 'react';
import Box from '@mui/material/Box';
import Container from "@mui/material/Container";

import { Application, Main as MainSection, Newsletter } from './components';

const CicloConsciente = () => {
  return (
    <>
      <Container style={{marginTop: '20px'}}>
        <MainSection/>
      </Container>
    </>
  );
};

export default CicloConsciente;
