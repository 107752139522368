import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import HomeImage from "images/about.jpeg";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ListItemText from "@mui/material/ListItemText";

const Features = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <>
      <Grid container spacing={4}>
        <Grid item container alignItems={'center'} xs={12} md={6}>
          <Box data-aos={isMd ? 'fade-right' : 'fade-up'}>
            <Box marginBottom={2}>

              <Box>
                <Typography variant={'h5'} fontWeight={700} gutterBottom>
                  Usalacopa
                </Typography>
                <Typography component={'p'} align={"justify"}>
                  Bienvenida a Usalacopa, donde aprendemos y nos enamoramos de nuestra ciclicidad, transformando la experiencia menstrual y celebrando el poder
                  femenino a través del conocimiento y la conexión.
                </Typography>
                <Typography marginTop={2}>
                  Somos más que una marca; somos una tribu que busca:
                </Typography>
              </Box>
              <Box marginBottom={3}>
                <Grid container spacing={1} sx={{ marginTop: 1 }}>
                  {[
                    'Fortalecer la conexión íntima con nuestro propio cuerpo',
                    'Honrar y celebrar la esencia del sagrado femenino',
                    'Comprender en profundidad el ciclo menstrual ovulatorio y vivir en sincronía con él',
                    'Recordarnos que somos una parte esencial del gran tejido de la vida',
                  ].map((item, i) => (
                    <Grid item xs={12} key={i}>
                      <Box
                        component={ListItem}
                        disableGutters
                        width={'auto'}
                        padding={0}
                      >
                        <Box
                          component={ListItemAvatar}
                          minWidth={'auto !important'}
                          marginRight={2}
                        >
                          <Box
                            component={Avatar}
                            bgcolor={theme.palette.secondary.main}
                            width={20}
                            height={20}
                          >
                            <svg
                              width={12}
                              height={12}
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fillRule="evenodd"
                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </Box>
                        </Box>
                        <ListItemText>
                          <Typography component={'p'}>{item}</Typography>
                        </ListItemText>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Box>
              <Box marginBottom={3}>

                <Typography marginBottom={2} component={'p'} align={"justify"}>
                  Aquí encontrarás alternativas sostenibles para gestionar tu menstruación y espacios enriquecedores, como círculos de mujeres, talleres y
                  cursos, para redescubrir la sabiduría interna y la magia que todas poseemos.
                </Typography>
                <Typography component={'p'} align={"justify"}>
                  Únete a la verdadera revolución: la ciclicidad consciente.
                  Tu ciclo, tu poder 🌸
                </Typography>
              </Box>

            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <Box
            component={"img"}
            height={1}
            width={1}
            src={HomeImage}
            alt="..."
            borderRadius={2}
            maxWidth={400}
            sx={{
              objectFit: 'cover',
              filter:
                theme.palette.mode === "dark" ? "brightness(0.7)" : "none",
              boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.4)',
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Features;
