import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { Box, Button, CircularProgress, TextField } from "@mui/material";

const StripeCheckoutForm = ({ sectionId, accessCode }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {

    if (!stripe) {
      console.error("Stripe.js has not loaded.");
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret",
    );

    if (!clientSecret) {
      console.error("No client secret found in URL.");
      return;
    } else {
      console.log("clientSecretNow: ", clientSecret);
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const hostname = window.location.hostname;
    const port = window.location.port;
    const returnUrl = `${window.location.protocol}//${hostname}${port ? `:${port}` : ""}/payment-complete?sectionId=${sectionId}&accessCode=${accessCode}`;

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: returnUrl,
        receipt_email: email,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs",
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <TextField
        sx={{ height: 54, marginBottom: "1rem" }}
        label="Email"
        type="email"
        variant="outlined"
        color="primary"
        size="medium"
        name="email"
        required={true}
        fullWidth
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Enter email address (for a payment receipt)"
      />
      <PaymentElement id="payment-element" options={paymentElementOptions}/>

      <Box marginTop="1rem" display={"flex"} justifyContent={"center"}>
        <Button
          variant="contained"
          color="secondary"
          disabled={isLoading || !stripe || !elements}
          id="submit"
          onClick={handleSubmit}
        >
          {isLoading ? <CircularProgress/> : "Pay now"}
        </Button>
      </Box>

      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
};

export default StripeCheckoutForm;
