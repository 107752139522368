import React, { useContext, useEffect, useState } from "react";
import { Typography, Box, Button, useTheme, IconButton, Toolbar, useMediaQuery } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { alpha } from "@mui/material/styles";
import PropTypes from "prop-types";
import { NavItem } from "./components";
import { useNavigate } from "react-router-dom";
import { AppContext } from "contexts/AuthContext";

const Topbar = ({ onSidebarOpen, pages, colorInvert = false }) => {
  const { isAuthenticated, mobileOpen, setMobileOpen } = useContext(AppContext);
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const [showToolbar, setShowToolbar] = useState(false);
  useEffect(() => {
    if (window.location.pathname.includes("view-course")) {
      setShowToolbar(true);
    }
  }, []);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      paddingX={2}
      paddingY={2}
      sx={{
        height: "50px", // Set a fixed height
        overflow: "hidden" // Prevents any overflow from affecting the height
      }}
    >
      {showToolbar && isMobile && (
        <Toolbar style={{ padding: 0, margin: 0, visibility: 'visible' }}>
          <IconButton
            color="black"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
          >
            <MenuIcon/>
          </IconButton>
        </Toolbar>
      )}

      {(!showToolbar || !isMobile) && (
        <Box
          display="flex"
          alignItems="center"
        >
          <Typography
            variant="h5"
            style={{
              cursor: "pointer",
              fontWeight: 700,
              fontFamily: '"Inter", sans-serif',
              background:
                "linear-gradient(180deg, transparent 82%, rgba(185.6, 176, 155.2, 0.5) 0%)",
            }}
            onClick={() => navigate("/")}
          >
            <span style={{ color: '#c29c6f' }}>usalacopa</span>
            <span style={{ color: "#ad4b00" }}>.com</span>
          </Typography>
        </Box>
      )}

      <Box
        sx={{ display: { xs: "none", md: "flex" } }}
        justifyContent="flex-end"
        alignItems="center"
      >
        {pages.common.map((currPage, index) => (
          <Box
            key={index}
            marginLeft={4}
            style={{ paddingTop: "10px", paddingBottom: "10px" }}
          >
            <NavItem
              title={currPage.title}
              href={currPage.href}
              id={currPage.title}
              items={currPage.landingPages}
              colorInvert={colorInvert}
            />
          </Box>
        ))}

        {isAuthenticated && pages.authenticated.map((currPage, index) => (
          <Box
            key={index}
            marginLeft={4}
            style={{ paddingTop: "10px", paddingBottom: "10px" }}
          >
            <NavItem
              title={currPage.title}
              href={currPage.href}
              onClick={currPage.onClick}
              id={currPage.title}
              items={currPage.landingPages}
              colorInvert={colorInvert}
            />
          </Box>
        ))}

        {!isAuthenticated && pages.unauthenticated.map((currPage, index) => {
          if (!currPage.hideOnUrls?.includes(window.location.pathname)) {
            return (
              <Box key={index} marginLeft={4}>
                <Button
                  variant="contained"
                  onClick={currPage.onClick}
                  size="large"
                  color="primary"
                >
                  {currPage.title}
                </Button>
              </Box>
            );
          }
          return null;
        })}

        {/*{isAuthenticated && (*/}
        {/*  <Box*/}
        {/*    marginLeft={4}*/}
        {/*    style={{ paddingTop: "10px", paddingBottom: "10px" }}*/}
        {/*  >*/}
        {/*    <NavItem*/}
        {/*      title="Account"*/}
        {/*      hideTitle={true}*/}
        {/*      icon={<AccountCircle/>}*/}
        {/*      id="account-dropdown-menu"*/}
        {/*      items={pages.authenticated}*/}
        {/*      colorInvert={colorInvert}*/}
        {/*    />*/}
        {/*  </Box>*/}
        {/*)}*/}

      </Box>

      <Box sx={{ display: { xs: "block", md: "none" } }} alignItems="center">
        <Button
          onClick={onSidebarOpen}
          aria-label="Menu"
          variant="outlined"
          sx={{
            borderRadius: 2,
            minWidth: "auto",
            padding: 1,
            borderColor: alpha(theme.palette.divider, 0.2),
          }}
        >
          <MenuIcon/>
        </Button>
      </Box>
    </Box>
  );
};

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func.isRequired,
  pages: PropTypes.shape({
    common: PropTypes.arrayOf(PropTypes.object).isRequired,
    authenticated: PropTypes.arrayOf(PropTypes.object).isRequired,
    unauthenticated: PropTypes.arrayOf(PropTypes.object).isRequired,
  }),
  colorInvert: PropTypes.bool,
};

export default Topbar;
