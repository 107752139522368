import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { alpha, useTheme } from "@mui/material/styles";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";
import { Badge } from "@mui/material";

const NavItem = ({
  title,
  id,
  hideTitle,
  items,
  colorInvert = false,
  href,
  icon = null,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openedPopoverId, setOpenedPopoverId] = useState(null);
  const [parentBadgeContent, setParentBadgeContent] = useState(0);
  const handleClick = (event, popoverId) => {
    if (items.length > 0) {
      setAnchorEl(event.target);
      setOpenedPopoverId(popoverId);
    } else {
      navigate(href);
    }
  };

  useEffect(() => {
    let badgeContent = 0;
    items.forEach((item) => {
      if (item.badgeContent) {
        badgeContent += item.badgeContent;
      }
    });
    setParentBadgeContent(badgeContent);
  }, [items]);

  const handleClose = () => {
    setAnchorEl(null);
    setOpenedPopoverId(null);
  };

  const [activeLink, setActiveLink] = useState("");
  useEffect(() => {
    setActiveLink(window && window.location ? window.location.pathname : "");
  }, []);

  const linkColor = colorInvert ? "common.white" : "text.primary";

  return (
    <Box>
      <Box
        display={"flex"}
        alignItems={"center"}
        aria-describedby={id}
        sx={{ cursor: "pointer" }}
        onClick={(e) => handleClick(e, id)}
      >
        {title && !hideTitle && (
          <Badge badgeContent={parentBadgeContent} color="error">
            <Typography
              color={openedPopoverId === id ? "indianred" : linkColor}
            >
              {title}
            </Typography>
          </Badge>
        )}
        {icon && (
          <Badge badgeContent={parentBadgeContent} color="error">
            {icon}
          </Badge>
        )}
        {items.length > 0 && (
          <ExpandMoreIcon
            sx={{
              marginLeft: theme.spacing(1 / 4),
              width: 16,
              height: 16,
              transform: openedPopoverId === id ? "rotate(180deg)" : "none",
              color: linkColor,
            }}
          />
        )}
      </Box>

      <Popover
        elevation={3}
        id={id}
        open={openedPopoverId === id}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        sx={{
          ".MuiPaper-root": {
            maxWidth: items.length > 12 ? 350 : 250,
            padding: 1,
            marginTop: 2,
            borderTopRightRadius: 0,
            borderTopLeftRadius: 0,
            borderBottomRightRadius: 8,
            borderBottomLeftRadius: 8,
            borderTop: `3px solid ${theme.palette.primary.main}`,
          },
        }}
      >
        <Grid container spacing={0.5}>
          {items.map((p, i) => (
            <Grid item key={i} xs={items.length > 12 ? 6 : 12}>
              <Button
                component={p.href ? "a" : "button"}
                onClick={(event) => {
                  if (p.onClick) {
                    p.onClick(event);
                  }
                  handleClose();
                }}
                href={p.href ? p.href : "#"}
                disabled={p.disabled ? p.disabled : false}
                fullWidth
                sx={{
                  justifyContent: "flex-start",
                  color:
                    activeLink === p.href
                      ? theme.palette.primary.main
                      : theme.palette.text.primary,
                  backgroundColor:
                    activeLink === p.href
                      ? alpha(theme.palette.primary.main, 0.1)
                      : "transparent",
                  fontWeight: 400,
                }}
              >
                <Badge
                  badgeContent={p.badgeContent ? p.badgeContent : 0}
                  color="error"
                >
                  {p.title}
                </Badge>
                {p.chip && (
                  <Chip
                    label={p.chip}
                    size="small"
                    sx={{
                      marginLeft: 1,
                      color: theme.palette.primary.main,
                    }}
                    variant="outlined"
                  />
                )}
              </Button>
            </Grid>
          ))}
        </Grid>
      </Popover>
    </Box>
  );
};

NavItem.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.element,
  hideTitle: PropTypes.bool,
  id: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  colorInvert: PropTypes.bool,
};

export default NavItem;
