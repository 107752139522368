import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import AboutMe from "images/aboutMe.png";

const Features = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <>
      <Grid container spacing={5} marginTop={1}>
        <Grid item container display={'flex'} justifyContent={'center'} xs={12} md={5}>
          <Box
            component={"img"}
            height={1}
            width={1}
            src={AboutMe}
            alt="..."
            borderRadius={2}
            maxWidth={460}
            sx={{
              objectFit: 'cover',
              filter:
                theme.palette.mode === "dark" ? "brightness(0.7)" : "none",
              boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.4)',
            }}
          />
        </Grid>
        <Grid item xs={12} md={7} justifyContent={'center'} alignItems={'center'}>
          <Box data-aos={isMd ? 'fade-right' : 'fade-up'}>
            <Box marginBottom={2}>
              <Typography variant={'h5'} sx={{ fontWeight: 700 }} gutterBottom>
                ¿Quién te acompañará?
              </Typography>

              <Typography color="text.secondary" variant={'h6'} align={'justify'} marginTop={2}>

                Soy Tatiana, tu guía y compañera en este viaje de autodescubrimiento y conexión con el ciclo menstrual ovulatorio.
                <br/><br/>
                Soy bióloga de profesión, realicé una maestría en Biotecnología de Plantas y actualmente soy estudiante de doctorado. Combino mi amor y
                conocimiento profundo de la vida y la naturaleza con la pasión por el sagrado femenino y su ciclicidad.
                <br/><br/>
                Soy la creadora de @usalacopa y facilitadora de círculos de mujeres, espacios para compartir, aprender y crecer juntas.
                <br/><br/>
                Bienvenida a este espacio ❤️

              </Typography>
            </Box>
          </Box>
        </Grid>

      </Grid>
    </>
  );
};

export default Features;
